import React from "react"

import styles from "./styles.module.css";
import {isMobile,isIOS} from 'react-device-detect';
import ChoiceCardArrow from "../../images/choice-card-arrow.inline.svg";
import GreenShape from "../../images/Green shape.png";
import { navigate } from "gatsby";

class PricingIntermediate extends React.Component {
    constructor(props) {
        super(props);
    }

    navigateTo = (path) => {
        navigate(path);
    }

    render() {
        const { } = this.props;

        var isMobileFinal = false, isIOSFinal = false;

        if (typeof window !== "undefined") {
          isMobileFinal = isMobile;
          isIOSFinal = isIOS;
        }

        return (
              <>
                <div className={[styles.pageContainer].join(' ')}>
                    <div className={styles.topContainer}>
                        <div className={[styles.titleContainer,'container',"d-flex flex-column justify-content-center"].join(" ")}>
                            <div className={["row", "justify-content-md-center"].join(" ")}>
                                <div className={[styles.pageTitle,'col-md-10','d-none','d-md-block'].join(' ')}>
                                    <h2>View MProfit plans and pricing</h2>
                                </div>
                                <div className={[styles.pageTitle,'col-md-10','d-md-none'].join(' ')}>
                                    <h2>View MProfit plans and pricing</h2>
                                </div>
                            </div>
                        </div>
                        <img src={GreenShape} className={styles.greenShape}/>
                    </div>
                    <div className={["container",styles.contentContainer].join(" ")}>
                        <div className={["row", "justify-content-md-center", styles.choiceCardContainer].join(" ")}>
                            <div className={[styles.choiceCard].join(' ')} onClick={() => {this.navigateTo('/pricing/investors')}}>
                                <div className={[styles.choiceCardTitleContainer].join(' ')}>
                                    <span className={[styles.choiceCardTitle].join(' ')}>For Investors</span>
                                    <ChoiceCardArrow/>
                                </div>
                                <div className={styles.choiceCardContent}>
                                    View our subscription plans designed for investors, traders, HNIs and family offices
                                </div>
                            </div>
                            <div className={[styles.choiceCard].join(' ')} onClick={() => {this.navigateTo('/pricing/wealth')}}>
                                <div className={[styles.choiceCardTitleContainer].join(' ')}>
                                    <span className={[styles.choiceCardTitle].join(' ')}>For Wealth Professionals</span>
                                    <ChoiceCardArrow/>
                                </div>
                                <div className={styles.choiceCardContent}>
                                    View our subscription plans designed for financial advisors, distributors, RIAs and CAs                                </div>
                                </div>
                        </div>
                    </div>
                </div>
              </>
          )
    }
}

export default PricingIntermediate
