import React from "react"
import FLayout from "../../components/flayout";
import { graphql } from "gatsby";
import PricingIntermediate from "../../components/pricingIntermediate";

const PricingPage = ({data}) => (
  <div className="v-100 d-flex flex-column">
    <FLayout showFooter={false} darkHeader={true} isFullPage={true} showSingleFooter={true} headerFixed={false} noSelBackground={true} seoFields={seoFields}>
      <PricingIntermediate/>
    </FLayout>
  </div>
)

const seoFields = {
  description: "Compare different MProfit plans and choose the one that is right for you. All our plans are extremely affordable, backed by our comprehensive import functionality and renowned customer support.",
  // lang,
  // meta,
  // keywords,
  title: "Pricing" 
}

export default PricingPage
